import PageHeader from '@/components/PageHeader'
import tableMixin from '@/minxin/table-minxin'
import { regionData, CodeToText, TextToCode } from 'element-china-area-data' // CodeToText
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
import root from '@/minxin/root'

const Index = {
  name: 'Address',
  mixins: [tableMixin, root],
  mounted() {
    regionData.map(item => {
      this.Province.push({
        label: item.label,
        value: item.value
      })
    })
  },
  data() {
    return {
      root_name: 'shdzmanage',
      root_msg: '收货地址管理',
      showViewer: false,
      MaterialFileList: [],
      Province: [],
      City: [],
      Area: [],
      showSetAddress: false,
      AddrType: [],
      rules: {
        Province: [
          { required: true, message: '请输入省', trigger: 'blur' }
        ],
        City: [
          { required: true, message: '请输入市', trigger: 'blur' }
        ],
        Area: [
          { required: true, message: '请输入区', trigger: 'blur' }
        ],
        Address: [
          { required: true, message: '请输入详细地址', trigger: 'blur' }
        ],
        AddrType: [
          { required: true, message: '地址用途', trigger: 'blur' }
        ],
        ContactName: [
          { required: true, message: '联系人', trigger: 'blur' }
        ],
        ContactTel: [
          { required: true, message: '联系电话', trigger: 'blur' }
        ]
      },
      ruleForm: {
        IDX: '',
        Province: '',
        City: '',
        Area: '',
        Address: '',
        DeptName: '',
        ContactName: '',
        ContactTel: '',
        Filepath: '',
        AddressCode: '',
        DefaultFlag: '',
        Base64String: '',
        FileExtName: '',
        AddrType: ''
      },
      ruleForm_temp: {
        IDX: '',
        Province: '',
        City: '',
        Area: '',
        Address: '',
        DeptName: '',
        ContactName: '',
        ContactTel: '',
        Filepath: '',
        AddressCode: '',
        DefaultFlag: '',
        Base64String: '',
        FileExtName: '',
        AddrType: ''
      },
      searchForm: {
        KeyWords: '',
        AddrType: '',
        CompanyID: '',
        PageIndex: 1,
        PageSize: 15
      },
      loading: false
    }
  },
  watch: {
    AddrType(a) {
      console.log(a)
      if (a.length === 2) {
        this.ruleForm.AddrType = 2
      }
      if (a.length === 1 && a[0] === '收货地址') {
        this.ruleForm.AddrType = 10
      }
      if (a.length === 1 && a[0] === '寄票地址') {
        this.ruleForm.AddrType = 20
      }
      if (a.length === 0) {
        this.ruleForm.AddrType = ''
      }
    }
  },
  components: {
    PageHeader,
    ElImageViewer

  },
  methods: {
    // 搜搜
    search() {
      this.getTableDataAsync()
    },
    handleChange(value) {
      console.log(value[0])
      this.City = []
      const City = []
      Object.assign(City, regionData.filter(item => item.value === value[0]))
      City[0].children.map(item => {
        this.City.push({
          label: item.label,
          value: item.value
        })
      })
    },
    handleChange1(value) {
      this.Area = []
      const City = regionData.filter(item => item.value === this.ruleForm.Province[0])
      const Area = City[0].children.filter(item => item.value === value[0])
      if (Area[0].children) {
        Area[0].children.map(item => {
          this.Area.push({
            label: item.label,
            value: item.value
          })
        })
      }
      // this.ruleForm1.City = CodeToText[value[0]]
    },
    handleChange2(value) {
      // this.ruleForm1.Area = CodeToText[value[0]]
    },
    // 新建地址
    creatArdess() {
      this.showSetAddress = true
      this.$refs.ruleForm.resetFields()
      this.resetUploader()
      // this.ruleForm = this.ruleForm_temp
      Object.assign(this.ruleForm, this.ruleForm_temp)
      this.ruleForm.IDX = ''
      this.ruleForm.Filepath = ''
      this.AddrType = [] // 地址用途清空
    },
    handleFileChange(e) {
      const _this = this
      const file = e.target.files[0]
      const size = Math.floor(file.size / 1024 / 1024)
      if (size > 5) {
        return this.$message({
          message: '图片大小不能超过5M',
          type: 'warning'
        })
      }
      console.log(file)
      _this.ruleForm.FileExtName = '.' + file.type.split('/')[1]
      var reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = (e) => {
        console.log(e)
        _this.ruleForm.Base64String = e.target.result // 传base64
        // _this.$api.OtherInStockUploadAccessory({
        //   FileExtName: '.' + file.type.split('/')[1],
        //   Base64String: e.target.result
        // }).then(res => {
        //   _this.ruleForm1.Filepath = res.Message
        // })
      }
    },
    // 修改地址
    edit(row) {
      // this.ruleForm.Filepath = ''
      for (const key in row) {
        console.log(key)
        this.ruleForm[key] = row[key]
      }
      this.showSetAddress = true
      console.log(this.ruleForm)
      const Province = this.ruleForm.Province
      const City = this.ruleForm.City
      const Area = this.ruleForm.Area
      this.ruleForm.Province = TextToCode[Province].code
      this.handleChange([this.ruleForm.Province])
      this.ruleForm.City = TextToCode[Province][City].code
      // this.handleChange1([this.ruleForm.City])
      this.Area = []
      const City2 = regionData.filter(item => item.value === this.ruleForm.Province)
      const Area2 = City2[0].children.filter(item => item.value === this.ruleForm.City)
      if (Area2[0].children) {
        Area2[0].children.map(item => {
          this.Area.push({
            label: item.label,
            value: item.value
          })
        })
      }
      this.ruleForm.Area = TextToCode[Province][City][Area].code

      // this.ruleForm.Province = TextToCode[row.Province].code
      if (this.ruleForm.AddrType === 10) {
        return this.AddrType.push('收货地址')
      }
      if (this.ruleForm.AddrType === 20) {
        return this.AddrType.push('寄票地址')
      }
      if (this.ruleForm.AddrType === 2) {
        this.AddrType.push('收货地址')
        this.AddrType.push('寄票地址')
        return
      }
    },
    // 新增地址/ 编辑
    submitAddress(formName) {
      console.log('this.ruleForm', this.ruleForm)
      this.$refs[formName].validate((valid) => {
        // this.$refs[formName].resetFields()
        if (!valid) return false
        if (!this.ruleForm.IDX) {
          this.ruleForm.Province = CodeToText[this.ruleForm.Province[0]]
          this.ruleForm.City = CodeToText[this.ruleForm.City[0]]
          this.ruleForm.Area = CodeToText[this.ruleForm.Area[0]]
        }
        if (this.ruleForm.IDX) {
          this.ruleForm.Province = CodeToText[this.ruleForm.Province]
          this.ruleForm.City = CodeToText[this.ruleForm.City]
          this.ruleForm.Area = CodeToText[this.ruleForm.Area]
        }
        this.loading = true
        this.showSetAddress = false

        this.$api.SaveUserAddress_sys(this.ruleForm).then(res => {
          if (res.RetCode !== '0') {
            this.loading = false
            return this.$message.error('错误：' + res.RetMsg)
          }
          this.refreshCurrentChange()
          this.$message.success(this.ruleForm.IDX ? '编辑地址成功' : '新建地址成功')
          this.AddrType = [] // 地址用途清空
          this.ruleForm.IDX = ''
          this.ruleForm.AddrType = ''
          this.loading = false
          this.resetUploader()
          return this.$refs[formName].resetFields() // 清空表单
        }).catch(() => {
          this.loading = false
          this.AddrType = [] // 地址用途清空
          this.ruleForm.IDX = ''
          this.ruleForm.AddrType = ''
        })
      })
    },
    // 预览附件
    viewImg(row) {
      console.log(this.b2bstaticUrl + row.Filepath)
      this.MaterialFileList = []
      this.MaterialFileList.push(this.b2bstaticUrl + row.Filepath)
      this.showViewer = true
    },
    // 复制到页面上实现获取数据逻辑
    async getTableDataAsync(page, pageSize) {
      this.searchForm.PageIndex = page || 1
      this.searchForm.PageSize = this.tablePageSize
      const response = await this.$api.GetUserAddressListByCompanyID(this.searchForm)
      this.tableData = response.Message
      this.tableTotalSize = response.Total /
      console.log(response)
      return response
    },
    // 清空文件输入框的值
    resetUploader() {
      // 重写一遍input元素
      document.getElementById('file').outerHTML = document.getElementById('file').outerHTML
      // 曾经@change="handleInput"绑定的事件解除了，要重新绑定一遍
      document.getElementById('file').addEventListener('change', this.handleFileChange)
    },
    // 删除地址
    delAdress(IDX) {
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$api.DeleteUseraddress({ IDX }).then(res => {
          console.log(res)
          if (res.RetCode !== '0') return this.$message.error('错误：' + res.RetMsg)
          this.refreshCurrentChange()
          return this.$message.error('删除地址成功')
        })
      }).catch(() => {

      })
    },
    close(e) {
      this.showSetAddress = false
      this.$refs[e].resetFields()
      this.resetUploader()
      this.ruleForm.IDX = ''
      this.AddrType = [] // 地址用途清空'
      this.ruleForm.AddrType = ''
      Object.assign(this.ruleForm, this.ruleForm_temp)
    }
  }
}

export default Index
