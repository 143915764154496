<template>
  <div class="address">
    <PageHeader title="地址管理" />
    <div class="searchView flex a-center m-tb-10 j-between SearchBox_30">
      <div class="out p-lr-10 flex a-center m-left-20 ModelBttton-white-30" @click="() => $refs.FieldManagement.openSetFieldManagement()">
        <i class="el-icon-setting f14 p-right-5" />   字段管理
      </div>
      <div class="flex m-left-20" style="flex: 1">
        <el-input
          v-model="searchForm.KeyWords"
          size="medium  "
          placeholder="可通过地址编号、地址、联系人、 联系电话搜索"
          class="rule-input-edit"
        >
          <template slot="prepend">
            <i class="el-icon-search" />
          </template>
          <div
            slot="append"
            class="cursor select_none"
            style="color: rgb(58, 120, 241)"
            @click="search"
          >
            搜 索
          </div>
        </el-input>
        <div v-if="$minCommon.checkMeau('shdzmanageadd', userBottons)" class="out p-lr-10 flex a-center m-right-10">
          <el-button
            class="ModelBttton-blue-30"
            round
            type="primary"
            icon="el-icon-s-fold"
            @click="creatArdess"
          >新建地址</el-button>
        </div>
      </div>
    </div>
    <div class="p-lr-20">
      <el-table
        ref="multipleTable"
        v-loading="tableLoading"
        height="calc(100vh - 325px)"
        :header-cell-style="headerCStyle"
        :data="tableData"
        tooltip-effect="dark"
        show-header
        row-key="IDX"
        border
      >
        <template v-for="(item,index) in tableListJson.address">
          <el-table-column
            v-if="item.isChecked && item.prop !== 'WriteTime' && item.prop !== 'Filepath' && item.prop !== 'DefaultFlag'"
            :key="index"
            :label="item.label"
            :prop="item.prop"
            show-overflow-tooltip
            align="center"
          />
          <el-table-column
            v-if="item.isChecked && item.prop === 'DefaultFlag'"
            :key="index"
            :label="item.label"
            :prop="item.prop"
            show-overflow-tooltip
            align="center"
          >
            <template v-if="item.isChecked && item.prop === 'DefaultFlag'" slot-scope="scope">
              {{ scope.row.DefaultFlag ? '是':'否' }}
            </template>
          </el-table-column>

          <el-table-column
            v-if="item.isChecked && item.prop === 'Filepath'"
            :key="index"
            :label="item.label"
            :prop="item.prop"
            show-overflow-tooltip
            align="center"
          >
            <template slot-scope="scope">
              <el-link v-if="scope.row.Filepath" class="f12" size="mini" type="primary" @click="viewImg(scope.row)">预览</el-link>
              <el-link v-else class="f12" size="mini" type="info" disabled>没有附件</el-link>
            </template>
          </el-table-column>
          <el-table-column
            v-if="item.isChecked && item.prop === 'WriteTime'"
            :key="index"
            :label="item.label"
            :prop="item.prop"
            show-overflow-tooltip
            align="center"
          >
            <template slot-scope="scope">
              {{ $minCommon.formatDate(new Date(scope.row.WriteTime), 'yyyy-MM-dd hh:mm') }}
            </template>
          </el-table-column>
        </template>
        <el-table-column prop="set" align="center" label="操作" width="50" fixed="right">
          <!--  slot-scope="scope" -->
          <template slot-scope="scope">
            <el-popover
              placement="left"
              trigger="hover"
              popper-class="test_pop_view"
            >
              <div class="DropDownList">
                <div v-if="$minCommon.checkMeau('shdzmanageedit', userBottons)" class="OperationButton" @click="edit(scope.row)">
                  <el-link :underline="false"><i class="el-icon-edit p-right-5" />编辑</el-link>
                </div>
                <div v-if="$minCommon.checkMeau('shdzmanagedelete', userBottons)" class="OperationButton" @click="delAdress(scope.row.IDX)">
                  <el-link :underline="false"><i class="el-icon-delete p-right-5" />删除</el-link>
                </div>
              </div>
              <i slot="reference" class="el-icon-more" />
            </el-popover>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination-container">
        <el-pagination
          :ref="paginationName"
          :total="tableTotalSize"
          :pager-count="pagerCount"
          :page-size="tablePageSize"
          :background="tableBackground"
          :current-page.sync="currentPage"
          layout="prev, pager, next, jumper"
          @current-change="handleCurrentChange($event)"
        />
      </div>
    </div>
    <!-- 新建地址 start -->
    <el-dialog class="DepartmentBox" top="5vh" width="400px!important" :visible="showSetAddress" :close-on-click-modal="false" :close-on-press-escape="false" custom-class="dialog_view" :show-close="false">
      <div class="PageHeader1 min-border-bottom flex j-between">
        <span class="Name">{{ ruleForm.IDX ? '编辑地址' :'新建地址' }}</span>
        <i class="el-dialog__close el-icon el-icon-close Close" @click="close('ruleForm')" />
      </div>
      <div class="m-top-20" />
      <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="80px" class="demo-ruleForm">
        <el-form-item label="省" prop="Province">
          <el-cascader
            v-model="ruleForm.Province"
            size="mini"
            :options="Province"
            style="width:100%"
            @change="handleChange"
          />
          <!-- <el-input v-model="ruleForm.Province" size="mini" /> -->
        </el-form-item>
        <el-form-item label="市" prop="City">
          <el-cascader
            v-model="ruleForm.City"
            size="mini"
            :options="City"
            style="width:100%"
            @change="handleChange1"
          />
          <!-- <el-input v-model="ruleForm.City" size="mini" /> -->
        </el-form-item>
        <el-form-item label="区" prop="Area">
          <el-cascader
            v-model="ruleForm.Area"
            size="mini"
            :options="Area"
            style="width:100%"
            @click="handleChange2"
          />
          <!-- <el-input v-model="ruleForm.Area" size="mini" /> -->
        </el-form-item>
        <el-form-item label="地址" prop="Address">
          <el-input v-model="ruleForm.Address" size="mini" />
        </el-form-item>
        <el-form-item label="地址编码" prop="AddressCode">
          <el-input v-model="ruleForm.AddressCode" size="mini" />
        </el-form-item>
        <el-form-item label="收货部门" prop="DeptName">
          <el-input v-model="ruleForm.DeptName" size="mini" />
        </el-form-item>
        <el-form-item label="联系人" prop="ContactName">
          <el-input v-model="ruleForm.ContactName" size="mini" />
        </el-form-item>
        <el-form-item label="联系电话" prop="ContactTel">
          <el-input v-model="ruleForm.ContactTel" size="mini" />
        </el-form-item>
        <el-form-item label="地址用途" prop="AddrType">
          <el-checkbox-group v-model="AddrType">
            <el-checkbox label="收货地址" />
            <el-checkbox label="寄票地址" />
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="是否默认" prop="DefaultFlag">
          <el-radio v-model="ruleForm.DefaultFlag" :label="1">是</el-radio>
          <el-radio v-model="ruleForm.DefaultFlag" :label="0">否</el-radio>
        </el-form-item>
        <el-form-item label="上传附件" prop="Filepath">
          <input id="file" type="file" name="file" multiple="multiple" @change="handleFileChange">
          <span class="f12 OriginalAttachment">原先附件：<span v-if="ruleForm.Filepath">{{ ruleForm.Filepath }}</span></span>
        </el-form-item>
        <div class="caozuo t-right p-tb-20 t_line m-top-40">
          <el-button @click="close('ruleForm')">取消</el-button>
          <el-button :loading="loading" type="primary" @click="submitAddress('ruleForm')">{{ ruleForm.IDX ? '确认' :'新增地址' }}</el-button>
        </div>
      </el-form>
    </el-dialog>
    <!-- 新建地址 end-->
    <el-image-viewer v-if="showViewer" style="z-index:9999" :url-list="MaterialFileList" :on-close="() => showViewer = false" />
    <!-- 字段搜索 -->
    <FieldManagement ref="FieldManagement" action-name="GetUserAddressListByCompanyID" :list="tableListJson.address" name="address" :change-list-table="changeListTable" />
  </div>
</template>

<script>
import Index from '@/minxin/system/address'
export default Index
</script>
<style lang="scss" scoped>
// @import "@/style/search.scss";
 .rule-input-edit ::v-deep {
    .el-input-group__prepend {
      background-color: #fff !important;
      border-radius: 20px 0 0 20px !important;
    }
    .el-input-group__append {
      background-color: #fff !important;
      border-radius: 0 20px 20px 0 !important;
    }
  }
   .PageHeader1{
    width: 100%;
    height: 50px;
    background-color: #fff;
    position: absolute;
    left: 0;
    top:0;
    display: flex;
    color:   #006fda;
    &::after{
        content: '';
        width: 3px;
        height:50%;
        background-color:  #006fda;
        position: absolute;
        left: 0;
        top: 25%;
    }
 }

</style>
